import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import 'remodal';

const majorSwiper = new Swiper('.specializations__carousel .swiper', {
  direction: 'horizontal',
  loop: true,
  speed: 400,
  spaceBetween: 24,
  centeredSlides: false,
  centeredSlidesBounds: true,
  grabCursor: true,
  simulateTouch: true,
  slidesPerView: 3,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
      0: {
          slidesPerView: 1.2,
          spaceBetween: 16,
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16
      },
      520: {
          slidesPerView: 1.8,
          spaceBetween: 16,
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16
      },
      768: {
          slidesPerView: 2.8,
          spaceBetween: 16,
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16
      },
      1024: {
          slidesPerView: 3,
          spaceBetween: 24,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0
      }
  }
});

const swiper = new Swiper('.partners__carousel .swiper', {
  direction: 'horizontal',
  loop: true,
  speed: 400,
  spaceBetween: 40,
  centeredSlides: true,
  centeredSlidesBounds: true,
  grabCursor: true,
  simulateTouch: true,
  slidesPerView: 3.5,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    320: {
        slidesPerView: 1.5,
        spaceBetween: 32
    },
    640: {
        slidesPerView: 2.5,
        spaceBetween: 16
    },
    768: {
      slidesPerView: 3.5,
      spaceBetween: 16
    },
    1024: {
        slidesPerView: 3.5,
        spaceBetween: 40
    }
  }
});

window.REMODAL_GLOBALS = {
    NAMESPACE: 'modal',
    DEFAULTS: {
        hashTracking: false
    }
};


document.addEventListener('DOMContentLoaded', function () {
  var formGroups = document.querySelectorAll('.form__group input, .form__group textarea');

  formGroups.forEach(function (input) {
      input.addEventListener('focus', function () {
          input.parentNode.classList.add('active');
      });

      input.addEventListener('blur', function () {
          if (input.value.trim() === '') {
              input.parentNode.classList.remove('active');
          }
      });

      input.addEventListener('input', function () {
          var formGroup = input.parentNode;

          if (input.value.trim() !== '') {
              formGroup.classList.add('active');
          } else {
              formGroup.classList.remove('active');
          }
      });
  });
});